// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.custome-animation{
    -webkit-animation: banner-move 60s infinite;
    animation: banner-move 60s infinite;
}
@keyframes banner-move {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    20% {
        -webkit-transform: translate3d(-30px, -30px, 0);
        transform: translate3d(-30px, -30px, 0)
    }

    40% {
        -webkit-transform: translate3d(30px, -30px, 0);
        transform: translate3d(30px, -30px, 0)
    }

    60% {
        -webkit-transform: translate3d(-30px, 30px, 0);
        transform: translate3d(-30px, 30px, 0)
    }

    80% {
        -webkit-transform: translate3d(30px, 30px, 0);
        transform: translate3d(30px, 30px, 0)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}